import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
	apiKey: process.env.GATSBY_API_KEY,
	authDomain: process.env.GATSBY_AUTH_DOMAIN,
	projectId: process.env.GATSBY_PROJECT_ID,
	appId: process.env.GATSBY_APP_ID,
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);
export default app;
