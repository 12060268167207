import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../utils/firebase';
import Emoji from './Emoji';
import { PhoneForm, FormError, SignUpButton, FormCaption, EmailInput, Body } from '../../styles';

const div = () => <div />;

export const Phone = () => {
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [button, setButton] = useState('');
	const [error, setError] = useState('');
	const [country, setCountry] = useState('US');
	const [done, setDone] = useState(false);
	const [clicked, setClicked] = useState(false);

	const handleSubmit = event => {
		event.preventDefault();
		if (clicked) return;
		setClicked(true);
		setButton('sending');
		var isValid = phone && isValidPhoneNumber(phone);

		if (!isValid) {
			setError('Invalid phone number');
			setClicked(false);
			setButton('');
			setTimeout(() => {
				setError('');
			}, 2000);
			return;
		}

		var coun = parsePhoneNumber(phone);
		if (coun) setCountry(coun.country);

		// Sending request for Customer.io
		const callable = httpsCallable(functions, 'signup');
		return callable({ phone, country: coun.country })
			.then(res => {
				if (res.data.success) {
					// Sending email to Google Sheets
					const formData = new FormData();
					formData.append('phone', phone);
					formData.append('country', coun.country);
					fetch(
						'https://script.google.com/macros/s/AKfycbxocb1vJ_wcKSLZgnpjw7WcrjEffbIaPAuU7vUsYjkWqTcgCED1MVEKYhUntNJUvNXZ/exec',
						{
							method: 'POST',
							body: formData,
						}
					);

					setButton('');
					setDone(true);
				} else {
					setError('Something went wrong. Please try again later.');
					setButton('');
					setTimeout(() => {
						setError('');
					}, 1500);
				}
			})
			.catch(() => {
				setError('Something went wrong. Please try again later.');
				setButton('');
				setTimeout(() => {
					setError('');
				}, 1500);
			})
			.finally(() => {
				setClicked(false);
			});
	};

	const handleSubmitEmail = event => {
		event.preventDefault();

		if (clicked) return;
		setClicked(true);

		if (!name || !email) {
			setError('Please provide all fields');
			setTimeout(() => {
				setError('');
			}, 2000);
			return;
		}

		setButton('sending');

		// Sending request to lambda for Customer.io
		const callable = httpsCallable(functions, 'signup');
		return callable({ phone, country, email, name })
			.then(() => {
				setButton('done');
				setTimeout(() => {
					setButton('');
					setCountry('US');
					setPhone('');
					setName('');
					setEmail('');
					setDone(false);
				}, 2500);
			})
			.catch(() => {
				setError('Something went wrong. Please try again later.');
				setButton('');
				setTimeout(() => {
					setError('');
				}, 1500);
			})
			.finally(() => {
				setClicked(false);
			});
	};

	return !done ? (
		<div>
			<PhoneForm onSubmit={handleSubmit}>
				<PhoneInput
					international
					limitMaxLength={true}
					countries={['US']}
					countrySelectProps={{ disabled: true, arrowComponent: div }}
					countryCallingCodeEditable={false}
					placeholder="Enter phone number"
					value={phone}
					onChange={res => {
						if (!res) return;
						var coun = parsePhoneNumber(res);
						if (coun) {
							setCountry(coun.country);
						}
						return setPhone(res);
					}}
					defaultCountry="US"
				/>
				<SignUpButton round submit className={button}>
					Join Now
				</SignUpButton>
				<SignUpButton round submit className={button}>
					Signing up...
				</SignUpButton>
				<SignUpButton round submit className={button}>
					<Emoji symbol="🎉" label="Party Popper" /> Check your phone
				</SignUpButton>
				{error && <FormError>{error}</FormError>}
			</PhoneForm>
		</div>
	) : (
		<>
			<div style={{ width: '360px' }}>
				{country === 'US' ? (
					<Body center font="0.85em">
						<Emoji symbol="🎉" label="Party Popper" /> Check your phone! <br />
						If you want to know more about us, leave your details below.
					</Body>
				) : (
					<Body center font="0.85em">
						Unfortunately, Envel is only live in the United States. If you want to know more about us, leave your
						details below.
					</Body>
				)}
				<EmailInput required type="text" placeholder="name" value={name} onChange={e => setName(e.target.value)} />
			</div>
			<div style={{ height: '15px' }} />
			<PhoneForm onSubmit={handleSubmitEmail}>
				<EmailInput
					required
					placeholder="email"
					value={email}
					type="email"
					onChange={e => setEmail(e.target.value?.toLowerCase())}
				/>
				<SignUpButton round submit className={button}>
					Send
				</SignUpButton>
				<SignUpButton round submit className={button}>
					Sending details...
				</SignUpButton>
				<SignUpButton round submit className={button}>
					Check your email
				</SignUpButton>
				{error && <FormError>{error}</FormError>}
			</PhoneForm>
		</>
	);
};

export default Phone;
